// --------------------------------------------------------------------------
// toggleClass.js
// --------------------------------------------------------------------------
// This file adds functionality for toggling a class on click of an element
// --------------------------------------------------------------------------

RN.toggleClass = function() {

  'use strict';

  function init() {
    byBehaviour('toggle-class').on('click', toggle);
    byBehaviour('toggle-class-hover').on('mouseover', toggle).on('mouseout', toggle);
  }

  function toggleOff() {
    byBehaviour('toggle-class').each(function(){
      var $trigger = $(this),
        targetName = $trigger.data('target'),
        $target = $('[data-element=' + targetName + ']'),
        targetClass = ($target.attr('class').split(" ")[0]) + '--active';

        if($target.length && $target.hasClass(targetClass)) {
          var triggerClass = ($trigger.attr('class').split(" ")[0]) + '--active';
    
          $('.' + targetClass).removeClass(targetClass);
          $trigger.toggleClass(triggerClass);
        }
    });
  }

  function toggle(e) {
    e.preventDefault();

    var $trigger = $(this),
      targetName = $trigger.data('target'),
      $target = $('[data-element=' + targetName + ']'),
      hideable = $target.data('hideable'),
      instance;
      
    if($target.length) {
      // Set modified classes
      var targetClass = ($target.attr('class').split(" ")[0]) + '--active';
      var triggerClass = ($trigger.attr('class').split(" ")[0]) + '--active';

      // Check if multiple instances on the page (i.e tabs)
      if($target.attr('data-instance') && e.type !== 'mouseout'){
        instance = $target.data('instance');

        // If so, remove other active classes when needed
        if(!$target.hasClass(targetClass) || hideable){

          // then add them to the current clicked item
          if(!$target.hasClass(targetClass)){
            $('.' + targetClass + '[data-instance=' + instance + ']').removeClass(targetClass);
            $('[data-behaviour=toggle-class][data-instance=' + instance + ']').each(function(){
              triggerClass = ($(this).attr('class').split(" ")[0]) + '--active';
              $(this).removeClass(triggerClass);
            });

            $target.addClass(targetClass);
            $trigger.trigger('active')

            $('[data-behaviour=toggle-class][data-target=' + targetName + ']').each(function(){
              triggerClass = ($(this).attr('class').split(" ")[0]) + '--active';
              $(this).toggleClass(triggerClass);
            })
          }
          else {
            $('.' + targetClass + '[data-instance=' + instance + ']').removeClass(targetClass);
            $('[data-behaviour=toggle-class][data-instance=' + instance + ']').each(function(){
              triggerClass = ($(this).attr('class').split(" ")[0]) + '--active';
              $(this).removeClass(triggerClass);
            });
            $trigger.trigger('inactive')
          }
        }
      }
      else {
        // Turn active classes on & off
        if($trigger.attr('data-reset-scroll')){
          $target.scrollTop(0);
        }

        $('[data-behaviour=toggle-class][data-target=' + targetName + ']').each(function(){
          triggerClass = ($(this).attr('class').split(" ")[0]) + '--active';
          $(this).toggleClass(triggerClass);
        })
        $target.toggleClass(targetClass);
      }

    }

    return false;
  }

  return {
    init: init,
    toggle: toggle,
    toggleOff: toggleOff
  };

}();